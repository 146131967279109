export default {
  /* Router */

  LOCATION_CHANGE_HANDLE: 'LOCATION_CHANGE_HANDLE',
  LOCATION_CHANGE_HANDLE__BOARD_FETCH: 'LOCATION_CHANGE_HANDLE__BOARD_FETCH',

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: 'SOCKET_DISCONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE: 'SOCKET_RECONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE__CORE_FETCH: 'SOCKET_RECONNECT_HANDLE__CORE_FETCH',

  /* Login */

  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE__SUCCESS: 'AUTHENTICATE__SUCCESS',
  AUTHENTICATE__FAILURE: 'AUTHENTICATE__FAILURE',
  AUTHENTICATE_ERROR_CLEAR: 'AUTHENTICATE_ERROR_CLEAR',

  LOGOUT: 'LOGOUT',

  /* Core */

  CORE_INITIALIZE: 'CORE_INITIALIZE',

  /* Modal */

  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  /* User */

  USER_CREATE: 'USER_CREATE',
  USER_CREATE__SUCCESS: 'USER_CREATE__SUCCESS',
  USER_CREATE__FAILURE: 'USER_CREATE__FAILURE',
  USER_CREATE_HANDLE: 'USER_CREATE_HANDLE',
  USER_CREATE_ERROR_CLEAR: 'USER_CREATE_ERROR_CLEAR',
  USER_UPDATE: 'USER_UPDATE',
  USER_UPDATE__SUCCESS: 'USER_UPDATE__SUCCESS',
  USER_UPDATE__FAILURE: 'USER_UPDATE__FAILURE',
  USER_UPDATE_HANDLE: 'USER_UPDATE_HANDLE',
  USER_EMAIL_UPDATE: 'USER_EMAIL_UPDATE',
  USER_EMAIL_UPDATE__SUCCESS: 'USER_EMAIL_UPDATE__SUCCESS',
  USER_EMAIL_UPDATE__FAILURE: 'USER_EMAIL_UPDATE__FAILURE',
  USER_EMAIL_UPDATE_ERROR_CLEAR: 'USER_EMAIL_UPDATE_ERROR_CLEAR',
  USER_PASSWORD_UPDATE: 'USER_PASSWORD_UPDATE',
  USER_PASSWORD_UPDATE__SUCCESS: 'USER_PASSWORD_UPDATE__SUCCESS',
  USER_PASSWORD_UPDATE__FAILURE: 'USER_PASSWORD_UPDATE__FAILURE',
  USER_PASSWORD_UPDATE_ERROR_CLEAR: 'USER_PASSWORD_UPDATE_ERROR_CLEAR',
  USER_USERNAME_UPDATE: 'USER_USERNAME_UPDATE',
  USER_USERNAME_UPDATE__SUCCESS: 'USER_USERNAME_UPDATE__SUCCESS',
  USER_USERNAME_UPDATE__FAILURE: 'USER_USERNAME_UPDATE__FAILURE',
  USER_USERNAME_UPDATE_ERROR_CLEAR: 'USER_USERNAME_UPDATE_ERROR_CLEAR',
  USER_AVATAR_UPDATE: 'USER_AVATAR_UPDATE',
  USER_AVATAR_UPDATE__SUCCESS: 'USER_AVATAR_UPDATE__SUCCESS',
  USER_AVATAR_UPDATE__FAILURE: 'USER_AVATAR_UPDATE__FAILURE',
  USER_DELETE: 'USER_DELETE',
  USER_DELETE__SUCCESS: 'USER_DELETE__SUCCESS',
  USER_DELETE__FAILURE: 'USER_DELETE__FAILURE',
  USER_DELETE_HANDLE: 'USER_DELETE_HANDLE',
  USER_TO_CARD_ADD: 'USER_TO_CARD_ADD',
  USER_TO_CARD_ADD__SUCCESS: 'USER_TO_CARD_ADD__SUCCESS',
  USER_TO_CARD_ADD__FAILURE: 'USER_TO_CARD_ADD__FAILURE',
  USER_TO_CARD_ADD_HANDLE: 'USER_TO_CARD_ADD_HANDLE',
  USER_FROM_CARD_REMOVE: 'USER_FROM_CARD_REMOVE',
  USER_FROM_CARD_REMOVE__SUCCESS: 'USER_FROM_CARD_REMOVE__SUCCESS',
  USER_FROM_CARD_REMOVE__FAILURE: 'USER_FROM_CARD_REMOVE__FAILURE',
  USER_FROM_CARD_REMOVE_HANDLE: 'USER_FROM_CARD_REMOVE_HANDLE',
  USER_TO_BOARD_FILTER_ADD: 'USER_TO_BOARD_FILTER_ADD',
  USER_FROM_BOARD_FILTER_REMOVE: 'USER_FROM_BOARD_FILTER_REMOVE',

  /* Project */

  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_CREATE__SUCCESS: 'PROJECT_CREATE__SUCCESS',
  PROJECT_CREATE__FAILURE: 'PROJECT_CREATE__FAILURE',
  PROJECT_CREATE_HANDLE: 'PROJECT_CREATE_HANDLE',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  PROJECT_UPDATE__SUCCESS: 'PROJECT_UPDATE__SUCCESS',
  PROJECT_UPDATE__FAILURE: 'PROJECT_UPDATE__FAILURE',
  PROJECT_UPDATE_HANDLE: 'PROJECT_UPDATE_HANDLE',
  PROJECT_BACKGROUND_IMAGE_UPDATE: 'PROJECT_BACKGROUND_IMAGE_UPDATE',
  PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS: 'PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS',
  PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE: 'PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_DELETE__SUCCESS: 'PROJECT_DELETE__SUCCESS',
  PROJECT_DELETE__FAILURE: 'PROJECT_DELETE__FAILURE',
  PROJECT_DELETE_HANDLE: 'PROJECT_DELETE_HANDLE',

  /* Project manager */

  PROJECT_MANAGER_CREATE: 'PROJECT_MANAGER_CREATE',
  PROJECT_MANAGER_CREATE__SUCCESS: 'PROJECT_MANAGER_CREATE__SUCCESS',
  PROJECT_MANAGER_CREATE__FAILURE: 'PROJECT_MANAGER_CREATE__FAILURE',
  PROJECT_MANAGER_CREATE_HANDLE: 'PROJECT_MANAGER_CREATE_HANDLE',
  PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH: 'PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH',
  PROJECT_MANAGER_DELETE: 'PROJECT_MANAGER_DELETE',
  PROJECT_MANAGER_DELETE__SUCCESS: 'PROJECT_MANAGER_DELETE__SUCCESS',
  PROJECT_MANAGER_DELETE__FAILURE: 'PROJECT_MANAGER_DELETE__FAILURE',
  PROJECT_MANAGER_DELETE_HANDLE: 'PROJECT_MANAGER_DELETE_HANDLE',

  /* Board */

  BOARD_CREATE: 'BOARD_CREATE',
  BOARD_CREATE__SUCCESS: 'BOARD_CREATE__SUCCESS',
  BOARD_CREATE__FAILURE: 'BOARD_CREATE__FAILURE',
  BOARD_CREATE_HANDLE: 'BOARD_CREATE_HANDLE',
  BOARD_FETCH: 'BOARD_FETCH',
  BOARD_FETCH__SUCCESS: 'BOARD_FETCH__SUCCESS',
  BOARD_FETCH__FAILURE: 'BOARD_FETCH__FAILURE',
  BOARD_UPDATE: 'BOARD_UPDATE',
  BOARD_UPDATE__SUCCESS: 'BOARD_UPDATE__SUCCESS',
  BOARD_UPDATE__FAILURE: 'BOARD_UPDATE__FAILURE',
  BOARD_UPDATE_HANDLE: 'BOARD_UPDATE_HANDLE',
  BOARD_DELETE: 'BOARD_DELETE',
  BOARD_DELETE__SUCCESS: 'BOARD_DELETE__SUCCESS',
  BOARD_DELETE__FAILURE: 'BOARD_DELETE__FAILURE',
  BOARD_DELETE_HANDLE: 'BOARD_DELETE_HANDLE',

  /* Board membership */

  BOARD_MEMBERSHIP_CREATE: 'BOARD_MEMBERSHIP_CREATE',
  BOARD_MEMBERSHIP_CREATE__SUCCESS: 'BOARD_MEMBERSHIP_CREATE__SUCCESS',
  BOARD_MEMBERSHIP_CREATE__FAILURE: 'BOARD_MEMBERSHIP_CREATE__FAILURE',
  BOARD_MEMBERSHIP_CREATE_HANDLE: 'BOARD_MEMBERSHIP_CREATE_HANDLE',
  BOARD_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH: 'BOARD_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH',
  BOARD_MEMBERSHIP_DELETE: 'BOARD_MEMBERSHIP_DELETE',
  BOARD_MEMBERSHIP_DELETE__SUCCESS: 'BOARD_MEMBERSHIP_DELETE__SUCCESS',
  BOARD_MEMBERSHIP_DELETE__FAILURE: 'BOARD_MEMBERSHIP_DELETE__FAILURE',
  BOARD_MEMBERSHIP_DELETE_HANDLE: 'BOARD_MEMBERSHIP_DELETE_HANDLE',

  /* Label */

  LABEL_CREATE: 'LABEL_CREATE',
  LABEL_CREATE__SUCCESS: 'LABEL_CREATE__SUCCESS',
  LABEL_CREATE__FAILURE: 'LABEL_CREATE__FAILURE',
  LABEL_CREATE_HANDLE: 'LABEL_CREATE_HANDLE',
  LABEL_UPDATE: 'LABEL_UPDATE',
  LABEL_UPDATE__SUCCESS: 'LABEL_UPDATE__SUCCESS',
  LABEL_UPDATE__FAILURE: 'LABEL_UPDATE__FAILURE',
  LABEL_UPDATE_HANDLE: 'LABEL_UPDATE_HANDLE',
  LABEL_DELETE: 'LABEL_DELETE',
  LABEL_DELETE__SUCCESS: 'LABEL_DELETE__SUCCESS',
  LABEL_DELETE__FAILURE: 'LABEL_DELETE__FAILURE',
  LABEL_DELETE_HANDLE: 'LABEL_DELETE_HANDLE',
  LABEL_TO_CARD_ADD: 'LABEL_TO_CARD_ADD',
  LABEL_TO_CARD_ADD__SUCCESS: 'LABEL_TO_CARD_ADD__SUCCESS',
  LABEL_TO_CARD_ADD__FAILURE: 'LABEL_TO_CARD_ADD__FAILURE',
  LABEL_TO_CARD_ADD_HANDLE: 'LABEL_TO_CARD_ADD_HANDLE',
  LABEL_FROM_CARD_REMOVE: 'LABEL_FROM_CARD_REMOVE',
  LABEL_FROM_CARD_REMOVE__SUCCESS: 'LABEL_FROM_CARD_REMOVE__SUCCESS',
  LABEL_FROM_CARD_REMOVE__FAILURE: 'LABEL_FROM_CARD_REMOVE__FAILURE',
  LABEL_FROM_CARD_REMOVE_HANDLE: 'LABEL_FROM_CARD_REMOVE_HANDLE',
  LABEL_TO_BOARD_FILTER_ADD: 'LABEL_TO_BOARD_FILTER_ADD',
  LABEL_FROM_BOARD_FILTER_REMOVE: 'LABEL_FROM_BOARD_FILTER_REMOVE',

  /* List */

  LIST_CREATE: 'LIST_CREATE',
  LIST_CREATE__SUCCESS: 'LIST_CREATE__SUCCESS',
  LIST_CREATE__FAILURE: 'LIST_CREATE__FAILURE',
  LIST_CREATE_HANDLE: 'LIST_CREATE_HANDLE',
  LIST_UPDATE: 'LIST_UPDATE',
  LIST_UPDATE__SUCCESS: 'LIST_UPDATE__SUCCESS',
  LIST_UPDATE__FAILURE: 'LIST_UPDATE__FAILURE',
  LIST_UPDATE_HANDLE: 'LIST_UPDATE_HANDLE',
  LIST_DELETE: 'LIST_DELETE',
  LIST_DELETE__SUCCESS: 'LIST_DELETE__SUCCESS',
  LIST_DELETE__FAILURE: 'LIST_DELETE__FAILURE',
  LIST_DELETE_HANDLE: 'LIST_DELETE_HANDLE',

  /* Card */

  CARD_CREATE: 'CARD_CREATE',
  CARD_CREATE__SUCCESS: 'CARD_CREATE__SUCCESS',
  CARD_CREATE__FAILURE: 'CARD_CREATE__FAILURE',
  CARD_CREATE_HANDLE: 'CARD_CREATE_HANDLE',
  CARD_FETCH: 'CARD_FETCH',
  CARD_FETCH__SUCCESS: 'CARD_FETCH__SUCCESS',
  CARD_FETCH__FAILURE: 'CARD_FETCH__FAILURE',
  CARD_UPDATE: 'CARD_UPDATE',
  CARD_UPDATE__SUCCESS: 'CARD_UPDATE__SUCCESS',
  CARD_UPDATE__FAILURE: 'CARD_UPDATE__FAILURE',
  CARD_UPDATE_HANDLE: 'CARD_UPDATE_HANDLE',
  CARD_TRANSFER: 'CARD_TRANSFER',
  CARD_TRANSFER__SUCCESS: 'CARD_TRANSFER__SUCCESS',
  CARD_TRANSFER__FAILURE: 'CARD_TRANSFER__FAILURE',
  CARD_DELETE: 'CARD_DELETE',
  CARD_DELETE__SUCCESS: 'CARD_DELETE__SUCCESS',
  CARD_DELETE__FAILURE: 'CARD_DELETE__FAILURE',
  CARD_DELETE_HANDLE: 'CARD_DELETE_HANDLE',

  /* Task */

  TASK_CREATE: 'TASK_CREATE',
  TASK_CREATE__SUCCESS: 'TASK_CREATE__SUCCESS',
  TASK_CREATE__FAILURE: 'TASK_CREATE__FAILURE',
  TASK_CREATE_HANDLE: 'TASK_CREATE_HANDLE',
  TASK_UPDATE: 'TASK_UPDATE',
  TASK_UPDATE__SUCCESS: 'TASK_UPDATE__SUCCESS',
  TASK_UPDATE__FAILURE: 'TASK_UPDATE__FAILURE',
  TASK_UPDATE_HANDLE: 'TASK_UPDATE_HANDLE',
  TASK_DELETE: 'TASK_DELETE',
  TASK_DELETE__SUCCESS: 'TASK_DELETE__SUCCESS',
  TASK_DELETE__FAILURE: 'TASK_DELETE__FAILURE',
  TASK_DELETE_HANDLE: 'TASK_DELETE_HANDLE',

  /* Attachment */

  ATTACHMENT_CREATE: 'ATTACHMENT_CREATE',
  ATTACHMENT_CREATE__SUCCESS: 'ATTACHMENT_CREATE__SUCCESS',
  ATTACHMENT_CREATE__FAILURE: 'ATTACHMENT_CREATE__FAILURE',
  ATTACHMENT_CREATE_HANDLE: 'ATTACHMENT_CREATE_HANDLE',
  ATTACHMENT_UPDATE: 'ATTACHMENT_UPDATE',
  ATTACHMENT_UPDATE__SUCCESS: 'ATTACHMENT_UPDATE__SUCCESS',
  ATTACHMENT_UPDATE__FAILURE: 'ATTACHMENT_UPDATE__FAILURE',
  ATTACHMENT_UPDATE_HANDLE: 'ATTACHMENT_UPDATE_HANDLE',
  ATTACHMENT_DELETE: 'ATTACHMENT_DELETE',
  ATTACHMENT_DELETE__SUCCESS: 'ATTACHMENT_DELETE__SUCCESS',
  ATTACHMENT_DELETE__FAILURE: 'ATTACHMENT_DELETE__FAILURE',
  ATTACHMENT_DELETE_HANDLE: 'ATTACHMENT_DELETE_HANDLE',

  /* Actions */

  ACTIONS_FETCH: 'ACTIONS_FETCH',
  ACTIONS_FETCH__SUCCESS: 'ACTIONS_FETCH__SUCCESS',
  ACTIONS_FETCH__FAILURE: 'ACTIONS_FETCH__FAILURE',

  /* Action */

  ACTION_CREATE_HANDLE: 'ACTION_CREATE_HANDLE',
  ACTION_UPDATE_HANDLE: 'ACTION_UPDATE_HANDLE',
  ACTION_DELETE_HANDLE: 'ACTION_DELETE_HANDLE',

  /* Comment action */

  COMMENT_ACTION_CREATE: 'COMMENT_ACTION_CREATE',
  COMMENT_ACTION_CREATE__SUCCESS: 'COMMENT_ACTION_CREATE__SUCCESS',
  COMMENT_ACTION_CREATE__FAILURE: 'COMMENT_ACTION_CREATE__FAILURE',
  COMMENT_ACTION_UPDATE: 'COMMENT_ACTION_UPDATE',
  COMMENT_ACTION_UPDATE__SUCCESS: 'COMMENT_ACTION_UPDATE__SUCCESS',
  COMMENT_ACTION_UPDATE__FAILURE: 'COMMENT_ACTION_UPDATE__FAILURE',
  COMMENT_ACTION_DELETE: 'COMMENT_ACTION_DELETE',
  COMMENT_ACTION_DELETE__SUCCESS: 'COMMENT_ACTION_DELETE__SUCCESS',
  COMMENT_ACTION_DELETE__FAILURE: 'COMMENT_ACTION_DELETE__FAILURE',

  /* Notification */

  NOTIFICATION_CREATE_HANDLE: 'NOTIFICATION_CREATE_HANDLE',
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
  NOTIFICATION_DELETE__SUCCESS: 'NOTIFICATION_DELETE__SUCCESS',
  NOTIFICATION_DELETE__FAILURE: 'NOTIFICATION_DELETE__FAILURE',
  NOTIFICATION_DELETE_HANDLE: 'NOTIFICATION_DELETE_HANDLE',
};
